import {REPORT_BUILDER_API} from '@/api/report-builder';
import {apiError} from '@/utils/api';
import {captureSection} from '@/utils/arrays';
import {getSingleUrlParam} from '@/utils/url';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {notification as AntdNotification} from 'antd';
import Jspdf from 'jspdf';
import {toJS} from 'mobx';
import {cast, flow, types} from 'mobx-state-tree';
import moment from 'moment';
import {isArray} from 'lodash';
import {getDefaultLayoutWithCheckedSections} from '@/utils/reportBuilder';

const reportsOTListModel = types.model({
  id: types.maybeNull(types.number),
  customer: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  notionPageId: types.maybeNull(types.string),
});

const facebookAds = types.model({
  id: types.maybeNull(types.number),
  business: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  accountStatus: types.maybeNull(types.string),
});

const reportGoogleAd = types.model({
  id: types.maybeNull(types.number),
  descriptiveName: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  accountId: types.maybeNull(types.number),
  managerId: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
});

const reportsListModel = types.model({
  hostname: types.maybeNull(types.string),
  isMatch: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  propertyId: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
});
const reportsListKrtModel = types.model({
  hostname: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
  postProcessingUpdatedAt: types.maybeNull(types.string),
  trackedKeywordsCount: types.maybeNull(types.number),
});
const weekDayModel = types.model({
  day: types.maybeNull(types.number),
  device: types.maybeNull(types.string),
  impressions: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
});

const googleChangeChart = types.model({
  date: types.maybeNull(types.string),
  positionChangeCumulative: types.maybeNull(types.number),
  positionsFallen: types.maybeNull(types.number),
  positionsNet: types.maybeNull(types.number),
  positionsRisen: types.maybeNull(types.number),
});

const metricsByDeviceModel = types.model({
  clicks: types.maybeNull(types.number),
  device: types.maybeNull(types.string),
  clicksDelta: types.maybeNull(types.number),
  ctr: types.maybeNull(types.number),
  ctrDelta: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
});
const trafficWeekModel = types.model({
  clicks: types.maybeNull(types.number),
  device: types.maybeNull(types.string),
  clicksDelta: types.maybeNull(types.number),
  day: types.maybeNull(types.number),
});
const keywordResultsModel = types.model({
  avgPosCur: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  isWatchlisted: types.maybeNull(types.boolean),
  kw: types.maybeNull(types.string),
  lastSeenAt: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  // searchIntent: types.maybeNull(types.string),
  opportunityScore: types.maybeNull(types.number),
  pagesNum: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
});
const reportGSCDetailsDataModel = types.model({
  googleRankChangeChart: types.maybeNull(types.array(googleChangeChart)),
  impressionsByWeekday: types.maybeNull(types.array(weekDayModel)),
  metricsByDevice: types.maybeNull(types.array(metricsByDeviceModel)),
  trafficByWeekday: types.maybeNull(types.array(trafficWeekModel)),
  topKeywords: types.maybeNull(types.model({
    count: types.maybeNull(types.number),
    results: types.maybeNull(types.array(keywordResultsModel)),
  })),
  topMetrics: types.maybeNull(types.model({
    avgPosition: types.maybeNull(types.number),
    avgPositionDelta: types.maybeNull(types.number),
    impressions: types.maybeNull(types.number),
    impressionsDelta: types.maybeNull(types.number),
    totalKeywords: types.maybeNull(types.number),
    totalKeywordsDelta: types.maybeNull(types.number),
    traffic: types.maybeNull(types.number),
    trafficDelta: types.maybeNull(types.number),
  })),
});
const channelsModel = types.model({
  newUsers: types.maybeNull(types.number),
  sessionDefaultChannelGroup: types.maybeNull(types.string),
  totalUsers: types.maybeNull(types.number),
});
const conversionsBySourceModel = types.model({
  conversions: types.maybeNull(types.number),
  source: types.maybeNull(types.string),
});
const revenueBySourceModel = types.model({
  source: types.maybeNull(types.string),
  totalRevenue: types.maybeNull(types.number),
});
const sourceMediumModel = types.model({
  engagementRate: types.maybeNull(types.number),
  medium: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  totalUsers: types.maybeNull(types.number),
});
const topMetricsModel = types.model({
  engagedSessions: types.maybeNull(types.number),
  engagedSessionsDelta: types.maybeNull(types.number),
  newUsers: types.maybeNull(types.number),
  newUsersDelta: types.maybeNull(types.number),
  screenPageViews: types.maybeNull(types.number),
  screenPageViewsDelta: types.maybeNull(types.number),
  sessions: types.maybeNull(types.number),
  sessionsDelta: types.maybeNull(types.number),
  totalUsers: types.maybeNull(types.number),
  totalUsersDelta: types.maybeNull(types.number),
});
const topPagesModel = types.model({
  countryId: types.maybeNull(types.string),
  engagementRate: types.maybeNull(types.number),
  engagementRateDelta: types.maybeNull(types.number),
  fullPageUrl: types.maybeNull(types.string),
  totalUsers: types.maybeNull(types.number),
  totalUsersDelta: types.maybeNull(types.number),
});
const usersByMediumsModel = types.model({
  medium: types.maybeNull(types.string),
  totalUsers: types.maybeNull(types.number),
});
const usersTrendModel = types.model({
  date: types.maybeNull(types.string),
  newUsers: types.maybeNull(types.number),
  newUsersDelta: types.maybeNull(types.number),
  totalUsers: types.maybeNull(types.number),
  totalUsersDelta: types.maybeNull(types.number),
});

const evtResultModal = types.model({
  eventCount: types.maybeNull(types.number),
  eventCountPerUser: types.maybeNull(types.number),
  eventCountPerUserDelta: types.maybeNull(types.number),
  eventCountDelta: types.maybeNull(types.number),
  eventName: types.maybeNull(types.string),
  totalRevenue: types.maybeNull(types.number),
  totalRevenueDelta: types.maybeNull(types.number),
  totalUsers: types.maybeNull(types.number),
  totalUsersDelta: types.maybeNull(types.number),
});

const evtModal = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(evtResultModal)),
});

const reportsGAModel = types.model({
  channels: types.maybeNull(types.array(channelsModel)),
  conversionsBySource: types.maybeNull(types.array(conversionsBySourceModel)),
  revenueBySource: types.maybeNull(types.array(revenueBySourceModel)),
  sourceMedium: types.maybeNull(types.model({count: 30, results: types.maybeNull(types.array(sourceMediumModel))})),
  topMetrics: types.maybeNull(types.array(topMetricsModel)),
  topPages: types.maybeNull(types.model({count: 30, results: types.maybeNull(types.array(topPagesModel))})),
  usersByMediums: types.maybeNull(types.array(usersByMediumsModel)),
  usersTrend: types.maybeNull(types.array(usersTrendModel)),
  keyEvents: types.maybeNull(evtModal),
  hostname: types.maybeNull(types.string),
  totalUsersTrend: types.maybeNull(types.array(usersTrendModel)),
});
const trendModel = types.model({
  refdomains: types.maybeNull(types.number),
  backlinks: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const reportBacklinksDetailsDataModel = types.model({
  authority: types.maybeNull(types.number),
  refdomains: types.maybeNull(types.number),
  follow: types.maybeNull(types.number),
  backlinks: types.maybeNull(types.number),
  sponsored: types.maybeNull(types.number),
  ugc: types.maybeNull(types.number),
  text: types.maybeNull(types.number),
  frame: types.maybeNull(types.number),
  form: types.maybeNull(types.number),
  image: types.maybeNull(types.number),
  ip: types.maybeNull(types.number),
  backlinksTrend: types.maybeNull(types.array(trendModel)),
});
const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  search: types.maybeNull(types.string),
  ordering: types.maybeNull(types.string),
});
const krtChartDataModel = types.model({
  avgPositionHist: types.maybeNull(types.array(types.model({
    avgPosition: types.maybeNull(types.number),
    date: types.maybeNull(types.string),
  }))),
  avgPositionHistDelta: types.maybeNull(types.model({
    currentAvgPosition: types.maybeNull(types.number),
    previousAvgPosition: types.maybeNull(types.number),
    avgPositionDelta: types.maybeNull(types.number),
  })),
  avgSvHist: types.maybeNull(types.array(types.model({
    sv: types.maybeNull(types.number),
    date: types.maybeNull(types.string),
  }))),
  avgSvHistDelta: types.maybeNull(types.model({
    currentSv: types.maybeNull(types.number),
    previousSv: types.maybeNull(types.number),
    svDelta: types.maybeNull(types.number),
  })),
  top10PositionHist: types.maybeNull(types.array(types.model({
    avgPosition: types.maybeNull(types.number),
    kwCount: types.maybeNull(types.number),
    date: types.maybeNull(types.string),
  }))),
  top10PositionHistDelta: types.maybeNull(types.model({
    currentKwCount: types.maybeNull(types.number),
    previousKwCount: types.maybeNull(types.number),
    kwCountDelta: types.maybeNull(types.number),
  })),
  trackedKeywordsCount: types.maybeNull(types.number),
  trackedKeywordsHistory: types.maybeNull(types.array(types.model({
    date: types.maybeNull(types.string),
    keywordsAddedCount: types.maybeNull(types.number),
  }))),
});
const keywordDetailResultsModel = types.model({
  avgPositionDelta: types.maybeNull(types.number),
  currentAvgPosition: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  previousAvgPosition: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  topUrl: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});
const krtKeywordsDetailModel = types.model({
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  results: types.maybeNull(types.array(keywordDetailResultsModel)),
  totalPages: types.maybeNull(types.number),
});
const competitorsModel = types.model({
  url: types.maybeNull(types.string),
  currentSearchVisibility: types.maybeNull(types.number),
  previousSearchVisibility: types.maybeNull(types.number),
  searchVisibilityDelta: types.maybeNull(types.number),
  keywordsInTop10: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
});
const reportsSeoListModel = types.model({
  businessName: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  keywordBreakdown: types.maybeNull(types.array(
    types.model({
      keyword: types.maybeNull(types.string),
    }),
  )),
  historicalSnapshot: types.optional(types.array(
    types.model({
      date: types.maybeNull(types.string),
    }),
  ), []),
  id: types.maybeNull(types.number),
});
const reportsLocalSeoListModel = types.model({
  businessName: types.maybeNull(types.string),
  keywordBreakdown: types.maybeNull(types.array(
    types.model({
      keyword: types.maybeNull(types.string),
      availableDates: types.maybeNull(types.array(types.string)),
    }),
  )),
  id: types.maybeNull(types.number),
});
const serpsModel = types.model({
  address: types.maybeNull(types.string),
  allCategories: types.maybeNull(types.array(types.maybeNull(types.string))),
  category: types.maybeNull(types.string),
  dataCid: types.maybeNull(types.string),
  dataId: types.maybeNull(types.string),
  gpsCoordinates: types.maybeNull(types.model({
    longitude: types.maybeNull(types.number),
    latitude: types.maybeNull(types.number),
  })),
  link: types.maybeNull(types.string),
  // opening_hours
  phone: types.maybeNull(types.string),
  placeId: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  price: types.maybeNull(types.union(types.string, types.number)),
  priceParsed: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  reviews: types.maybeNull(types.number),
  thumbnail: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  webResultsLink: types.maybeNull(types.string),
});
const gscReportsListModel = types.model({
  hostname: types.maybeNull(types.string),
  isMatch: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  propertyId: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  countryCodeExists: types.maybeNull(types.boolean),
  countryCodes: types.maybeNull(types.array(types.string)),
});
const GMBListModel = types.model({
  id: types.maybeNull(types.number),
  locationId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  websiteUrl: types.maybeNull(types.string),
});
const BreakdownModel = types.model({
  device: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const PlatformDeviceBreakdownModel = types.model({
  total: types.maybeNull(types.number),
  breakdown: types.maybeNull(types.array(BreakdownModel)),
});
const MetricsByDayModel = types.model({
  businessDirectionRequests: types.maybeNull(types.number),
  businessImpressionsDesktopMaps: types.maybeNull(types.number),
  businessImpressionsDesktopSearch: types.maybeNull(types.number),
  businessImpressionsMobileMaps: types.maybeNull(types.number),
  businessImpressionsMobileSearch: types.maybeNull(types.number),
  callClicks: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  impressions: types.maybeNull(types.number),
  websiteClicks: types.maybeNull(types.number),
});
const GMBtopMetricsModel = types.model({
  callClicks: types.maybeNull(types.number),
  directionRequests: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  websiteClicks: types.maybeNull(types.number),
});
const searchkeywordsBreakdownModel = types.model({
  nextPageToken: types.maybeNull(types.string),
  searchKeywordsCounts: types.maybeNull(types.frozen([])),
});
const GmbReportDataModel = types.model({
  platformDeviceBreakdown: types.maybeNull(PlatformDeviceBreakdownModel),
  metricsByDay: types.maybeNull(types.array(MetricsByDayModel)),
  topMetrics: types.maybeNull(GMBtopMetricsModel),
  searchkeywordsBreakdown: types.maybeNull(searchkeywordsBreakdownModel),
});

const GaModel = types.model({
  gaSections: types.maybeNull(types.array(types.maybeNull(types.string))),
  gaPropertyId: types.maybeNull(types.string),
});

const GscModel = types.model({
  gscSections: types.maybeNull(types.array(types.maybeNull(types.string))),
  gscPropertyUrl: types.maybeNull(types.string),
});

const BacklinkModel = types.model({
  backlinksTarget: types.maybeNull(types.string),
  backlinksSections: types.maybeNull(types.array(types.maybeNull(types.string))),
  backlinksPropertyName: types.maybeNull(types.string),
});

const gmbModel = types.model({
  gmbIds: types.maybeNull(types.array(types.number)),
  gmbSections: types.maybeNull(types.array(types.maybeNull(types.string))),
});

const SeoModel = types.model({
  localSeoIds: types.maybeNull(types.array(types.number)),
  seoSections: types.maybeNull(types.array(types.maybeNull(types.string))),
});

const KrtModel = types.model({
  rankTrackerId: types.maybeNull(types.number),
  rankTrackerSections: types.maybeNull(types.array(types.maybeNull(types.string))),
});

const fbModel = types.model({
  facebookAdsAccountId: types.maybeNull(types.string),
});
const glModel = types.model({
  googleAdsAccountId: types.maybeNull(types.string),
});

const ReportDataModel = types.model({
  ga: types.maybeNull(GaModel),
  gsc: types.maybeNull(GscModel),
  backlinks: types.maybeNull(BacklinkModel),
  localSeo: types.maybeNull(SeoModel),
  gmb: types.maybeNull(gmbModel),
  rankTracker: types.maybeNull(KrtModel),
  facebookAds: types.maybeNull(fbModel),
  googleAds: types.maybeNull(glModel),
});

const layoutObj = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  layout: types.maybeNull(types.frozen([])),
});

const SingleProjectModel = types.model({
  id: types.maybeNull(types.number),
  projectId: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  dateRange: types.maybeNull(types.string),
  dateRangeStart: types.maybeNull(types.string),
  dateRangeEnd: types.maybeNull(types.string),
  reportsData: types.maybeNull(ReportDataModel),
  created: types.maybeNull(types.string),
  modified: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  emailEnabled: types.maybeNull(types.boolean),
  emailFreqDays: types.maybeNull(types.number),
  emailRecipients: types.maybeNull(types.array(types.string)),
  emailSelectedDay: types.maybeNull(types.number),
  layout: types.maybeNull(types.frozen([])),
  layoutType: types.maybeNull(types.string),
  notionPageId: types.maybeNull(types.string),
  customerClientId: types.maybeNull(types.number),
  isAiSummaryEnabled: types.maybeNull(types.boolean),
  shareUrl: types.maybeNull(types.string),
  ottoProjectUuid: types.maybeNull(types.string),
  aiSummary: types.maybeNull(types.frozen({})),
  hideEmptySections: types.maybeNull(types.boolean),
  customLayout: types.maybeNull(layoutObj),
});

const GmbModalRb = types.model({
  id: types.maybeNull(types.number),
  businessTitle: types.maybeNull(types.string),
  value: types.maybeNull(GmbReportDataModel),
});

const LocalSeoData = types.model({
  competitorResults: types.maybeNull(types.array(types.model({
    address: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
    gmbAveragePosition: types.maybeNull(types.number),
    gmbBestPosition: types.maybeNull(types.number),
    gmbWorstPosition: types.maybeNull(types.number),
    gmbCenterPosition: types.maybeNull(types.number),
    reviews: types.maybeNull(types.number),
    rating: types.maybeNull(types.number),
    occurrence: types.maybeNull(types.number),
  }))),
  locationReport: types.maybeNull(types.array(types.model({
    date: types.maybeNull(types.string),
    data: types.maybeNull(types.array(types.model({
      date: types.maybeNull(types.string),
      keyword: types.maybeNull(types.string),
      gmbPosition: types.maybeNull(types.number),
      location: types.maybeNull(types.model({
        lat: types.maybeNull(types.number),
        lon: types.maybeNull(types.number),
      })),
      serps: types.maybeNull(types.array(serpsModel)),
    }))),
  }))),
  snapshotData: types.maybeNull(types.model({
    gridSize: types.maybeNull(types.string),
    keyword: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    radiusMiles: types.maybeNull(types.number),
    spacing: types.maybeNull(types.number),
    center: types.maybeNull(types.model({
      coordinates: types.maybeNull(types.array(types.number)),
      type: types.maybeNull(types.string),
    })),
    feedback: types.maybeNull(types.model({
      reviews: types.maybeNull(types.number),
      rating: types.maybeNull(types.number),
    })),
    gridCoords: types.maybeNull(types.array(types.model({
      lat: types.maybeNull(types.number),
      lon: types.maybeNull(types.number),
    }))),
    keywordGridMetrics: types.maybeNull(types.array(types.model({
      keyword: types.maybeNull(types.string),
      gmbAveragePosition: types.maybeNull(types.number),
      gmbBestPosition: types.maybeNull(types.number),
      gmbWorstPosition: types.maybeNull(types.number),
      gmbCenterPosition: types.maybeNull(types.number),
    }))),
  })),
  dataCid: types.maybeNull(types.string),
  businessName: types.maybeNull(types.string),
  businessAddress: types.maybeNull(types.string),
});

const LocalSeoRb = types.model({
  id: types.maybeNull(types.number),
  rbSeoData: types.maybeNull(LocalSeoData),
});

export const DetailsStore = types
  .model({
    loadingGSCDetail: types.boolean,
    isExporting: types.boolean,
    loadingGADetail: types.boolean,
    loadingGSCList: types.boolean,
    loadingGAList: types.boolean,
    loadingOTList: types.boolean,
    loadingLocalSeoDetail: types.boolean,
    loadingLocalSeoDetailRb: types.boolean,
    topKeywordsLoading: types.boolean,
    loadingKrtTableDetail: types.boolean,
    loadingKrtList: types.boolean,
    loadingBacklinksDetail: types.boolean,
    loadingBacklinksList: types.boolean,
    loadingFb: types.boolean,
    loadingFbAge: types.boolean,
    loadingFbGender: types.boolean,
    loadinggl: types.maybeNull(types.boolean),
    loadingglMatrix: types.maybeNull(types.boolean),
    loadingglgraph: types.maybeNull(types.boolean),
    loadingglTable: types.maybeNull(types.boolean),
    loadingKrtDetail: types.boolean,
    topPagesLoading: types.boolean,
    keyEventLoading: types.boolean,
    isConfig: types.boolean,
    isSeoUpdate: types.boolean,
    isAllRender: types.maybeNull(types.array(types.string)),
    loadingTableData: types.boolean,
    loadingSeoList: types.boolean,
    loadingSeoProjectDetail: types.boolean,
    topUsersLoading: types.boolean,
    reportsGAList: types.maybeNull(types.array(reportsListModel)),
    reportsOTList: types.maybeNull(types.array(reportsOTListModel)),
    reportsGoogleAdList: types.maybeNull(types.array(reportGoogleAd)),
    reportsFbList: types.maybeNull(types.array(facebookAds)),
    reportsFbListUser: types.maybeNull(types.frozen({})),
    reportsGoogleListUser: types.maybeNull(types.frozen({})),
    activeTabKey: types.maybeNull(types.string),
    reportsFbListGender: types.maybeNull(types.frozen({})),
    reportsGoogleListMatrix: types.maybeNull(types.frozen({})),
    reportsGoogleListOverTime: types.maybeNull(types.frozen({})),
    reportsGoogleListByCampaign: types.maybeNull(types.frozen({})),
    reportsFbListAge: types.maybeNull(types.frozen({})),
    reportsFbPublisherUser: types.maybeNull(types.frozen({})),
    reportsFbLineChart: types.maybeNull(types.frozen({})),
    reportsFbBarChart: types.maybeNull(types.frozen({})),
    reportsSeoList: types.maybeNull(types.array(reportsSeoListModel)),
    reportsSeoProjectDetail: types.maybeNull(reportsSeoListModel),
    reportsGSCList: types.maybeNull(types.array(gscReportsListModel)),
    reportGSCDetailsData: types.maybeNull(reportGSCDetailsDataModel),
    reportBacklinksDetailData: types.maybeNull(reportBacklinksDetailsDataModel),
    reportGADetailData: types.maybeNull(reportsGAModel),
    localSeoDetailsData: types.maybeNull(types.array(LocalSeoRb)),
    periodStart: types.maybeNull(types.string),
    periodEnd: types.maybeNull(types.string),
    topKeywordsParams: paramsModel,
    reportBuilderPdfBar: types.number,
    reportsBacklinksList: types.maybeNull(types.array(types.model({
      id: types.maybeNull(types.number),
      metrics: types.maybeNull(types.model({
        url: types.maybeNull(types.string),
        countryCode: types.maybeNull(types.string),
      })),
    }))),
    krtTableParams: paramsModel,
    topPagesParams: paramsModel,
    keyEventParams: paramsModel,
    reportsKrtList: types.maybeNull(types.array(reportsListKrtModel)),
    backlinksDataForTable: types.maybeNull(types.frozen({})),
    reportKrtDetailData: types.maybeNull(types.model({
      chartData: types.maybeNull(krtChartDataModel),
      keywordsDetail: types.maybeNull(krtKeywordsDetailModel),
      competitors: types.maybeNull(types.array(competitorsModel)),
      hostname: types.maybeNull(types.string),
    })),
    topUsersParams: paramsModel,
    reportsLocalSeoList: types.maybeNull(types.array(reportsLocalSeoListModel)),
    GMBList: types.maybeNull(types.array(GMBListModel)),
    loadingGmbList: types.boolean,
    loadingGMBReportData: types.boolean,
    googleAdSelect: types.maybeNull(types.frozen({})),
    gmbReportData: types.maybeNull(GmbReportDataModel),
    gmbReportDataRB: types.maybeNull(types.array(GmbModalRb)),
    localSeoIdReport: types.maybeNull(types.array(types.number)),
    resetLayoutConfig: types.maybeNull(types.boolean),
    singleProjectData: types.maybeNull(SingleProjectModel),
    summaryOverview: types.maybeNull(types.string),
    isHideReport: types.maybeNull(types.boolean),
    defaultMap: types.maybeNull(types.model({
      keyword: types.maybeNull(types.string),
      availableDates: types.maybeNull(types.array(types.string)),
    })),
    siteExplorerParams: types.maybeNull(types.model({
      search: types.maybeNull(types.string),
      page: types.maybeNull(types.number),
      page_size: types.maybeNull(types.number),
    })),
    siteExplorerCount: types.maybeNull(types.number),
    loadingSingleProjectData: types.maybeNull(types.boolean),
    loadingAiSummary: types.maybeNull(types.boolean),
    loadingAiSummaryOverview: types.maybeNull(types.boolean),
    isConfirmModalVisible: types.boolean,
    isEdited: types.boolean,
    layoutType: types.maybeNull(types.string),
    graphDataRb: types.maybeNull(types.string),
    layoutState: types.maybeNull(types.model({
      lg: types.array(types.model({
        index: types.maybeNull(types.number),
        value: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
      })),
    })),
    localSeoLoading: types.boolean,
    creatingNotionBoard: types.boolean,
    addingNotionBoardTask: types.boolean,
    modalData: types.maybeNull(types.model({
      openModal: types.maybeNull(types.boolean),
      title: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      type: types.maybeNull(types.string),
      website: types.maybeNull(types.string),
      issueFound: types.maybeNull(types.string),
      fixed: types.maybeNull(types.string),
      dueDate: types.maybeNull(types.string),
      boardId: types.maybeNull(types.union(types.string, types.number)),
      boardName: types.maybeNull(types.string),
      modalType: types.maybeNull(types.string),
      rankingFactor: types.maybeNull(types.string),
      timeSavedWithOtto: types.maybeNull(types.string),
    })),
    boardsList: types.maybeNull(types.array(types.model({
      id: types.maybeNull(types.union(types.string, types.number)),
      name: types.maybeNull(types.string),
    }))),
    loadingBoardsList: types.boolean,
    addTaskBoard: types.maybeNull(types.boolean),
    fbLoader: types.maybeNull(types.boolean),
    glLoader: types.maybeNull(types.boolean),
    allLoaders: types.maybeNull(types.boolean),
    property: types.maybeNull(types.boolean),
    summaryHeight: types.maybeNull(types.number),
    btnDisabledOnLoading: types.maybeNull(types.boolean),
    seLoading: types.maybeNull(types.boolean),
    layouts: types.maybeNull(types.array(layoutObj)),
    backupLayout: types.maybeNull(types.model({
      layout: types.maybeNull(types.frozen([])),
      customLayout: types.maybeNull(layoutObj),
      layoutType: types.maybeNull(types.string),
    })),
    showLayoutConfirmModal: types.maybeNull(types.model({
      show: types.maybeNull(types.boolean),
      option: types.maybeNull(types.string),
    })),
    resetChangesObject: types.maybeNull(types.model({
      reset: types.maybeNull(types.boolean),
      option: types.maybeNull(types.string),
    })),
    currentLayout: types.maybeNull(types.frozen([])),
  }).views(self => ({
    get getReportsGAList() {
      return toJS(self.reportsGAList);
    },
    get allLoaderings() {
      // eslint-disable-next-line max-len
      return self.fbLoader ||( self.loadingLocalSeoDetail && self.singleProjectData?.reportsData.localSeo) || self.loadingglMatrix || self.loadingglTable || self.loadingglgraph || (self.loadingFbAge && !self.reportsFbListAge && self.singleProjectData?.reportsData?.facebookAds ? true : false) || (self.loadingFbGender && !self.reportsFbListGender && self.singleProjectData?.reportsData?.facebookAds ? true : false) || (self.loadingGADetail && !self.reportGADetailData && self.singleProjectData?.reportsData?.ga ? true : false) || (self.loadingGMBReportData && !self.gmbReportDataRB && self.singleProjectData?.reportsData?.gmb && self.singleProjectData?.reportsData?.gmb.gmbIds?.length ? true : false) || (self.loadingGSCDetail && (self.singleProjectData?.reportsData?.gsc?.gscPropertyUrl ? true : false) ) || (self.loadingKrtDetail && self.singleProjectData?.reportsData.rankTracker) || (self.seLoading && self.singleProjectData?.reportsData.backlinks);
    },
    get getReportsOTList() {
      return toJS(self.reportsOTList);
    },
    get getReportsFbList() {
      return toJS(self.reportsFbList);
    },
    get getReportsGoogleAdList() {
      return toJS(self.reportsGoogleAdList);
    },
    get getBacklinksDataForTable() {
      return toJS(self.backlinksDataForTable);
    },
    get getReportsKrtList() {
      return toJS(self.reportsKrtList);
    },
    get getReportKrtDetailData() {
      return toJS(self.reportKrtDetailData);
    },
    get getKrtTableParams() {
      return toJS(self.krtTableParams);
    },
    get getReportsBacklinksList() {
      return toJS(self.reportsBacklinksList);
    },
    get getReportsGSCList() {
      return toJS(self.reportsGSCList);
    },
    get getReportGSCDetailsData() {
      return toJS(self.reportGSCDetailsData);
    },
    get getReportGADetailData() {
      return toJS(self.reportGADetailData);
    },
    get getTopKeywordsParams() {
      return toJS(self.topKeywordsParams);
    },
    get getReportsSeoList() {
      return toJS(self.reportsSeoList);
    },
    get getLocalSeoDetailsData() {
      return toJS(self.localSeoDetailsData);
    },
    get getTopUsersParams() {
      return toJS(self.topUsersParams);
    },
    get getTopPagesParams() {
      return toJS(self.topPagesParams);
    },
    get getKeyEventParams() {
      return toJS(self.keyEventParams);
    },
    get getReportBacklinksDetailData() {
      return toJS(self.reportBacklinksDetailData);
    },
    get getReportsLocalSeoList() {
      return toJS(self.reportsLocalSeoList);
    },
    get getGMBList() {
      return toJS(self.GMBList);
    },
    get getGMBReportData() {
      return toJS(self.gmbReportData);
    },
    get getLayoutsList() {
      return toJS(self.layouts);
    },
  })).actions(self => {
    const getGMBReportDataValue = id => {
      const gmbData = toJS(self.gmbReportDataRB);

      if (gmbData?.length) {
        return toJS(gmbData.find(item=> item.id == id)?.value);
      } else return null;
    };

    const setGoogleAdSelect = value => self.googleAdSelect = value;
    const setProperty = value => self.property = value;
    const publicHash =() => {
      return getSingleUrlParam('public_hash');
    };
    const setSiteExplorerParams = (value, isLoadMore = false, noApiCall?: boolean, noLoading?: boolean) => {
      self.siteExplorerParams = value;
      if (!noApiCall) {
        loadReportBuilderBacklinksList(noLoading, isLoadMore);
      }
    };
    const setSeLoading = value => self.seLoading = value;
    const setLayoutState = value => self.layoutState = value;
    const setIsLayoutEdit = value => self.isEdited = value;
    const setHideReport = value => self.isHideReport = value;

    const loadReportBuilderGAList = flow(function* (noLoading?: boolean) {
      if (publicHash()) return;
      if (!noLoading) {
        self.loadingGAList = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.loadReportBuilderGAListData();
        // self.total = response?.count;
        self.reportsGAList = cast(response);
        // const metricsStatus = response?.results?.filter(item => item?.status == 'PENDING');
        // if (metricsStatus?.length && self.listApiRepolling) {
        //   yield new Promise(r => setTimeout(r, 2000));
        //   return loadReportBuilderList(true);
        // }
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingGAList = false;
      }
    });

    const loadReportBuilderOTList = flow(function* (noLoading?: boolean) {
      if (publicHash()) return;
      if (!noLoading) {
        self.loadingOTList = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.loadReportBuilderOTListData();
        self.reportsOTList = cast(response);
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingOTList = false;
      }
    });

    const disconnectGa = flow(function* (settingId: number) {
      if (publicHash()) return;
      try {
        const response = yield REPORT_BUILDER_API.disconnectGa(settingId);
        return response;
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const resetLayout = value => self.resetLayoutConfig = value;
    const resetProject = () => {
      self.singleProjectData = null;
    };

    const updateSingleProjectData = data => {
      self.singleProjectData = cast(data);
      setLayoutType(data?.layoutType);
    };

    const loadSingleProjectData = flow(function* (id) {
      self.loadingSingleProjectData = true;
      let response;
      try {
        if (id) {
          response = yield REPORT_BUILDER_API.loadSingleProjectDetailData(id, publicHash());
          if (response.reportsData?.gmb && !isArray(response.reportsData?.gmb?.gmbIds)) {
            response.reportsData.gmb.gmbIds = [response.reportsData?.gmb?.gmbIds];
          }
          if (response.reportsData?.localSeo && !isArray(response.reportsData.localSeo.localSeoIds)) {
            response.reportsData.localSeo.localSeoIds = [response.reportsData.localSeo.localSeoIds];
          }
          response.layout = response.layout?.map(item=> {
            if (item?.name == 'Map' && !item.index) {
              return {...item, index: response?.reportsData?.localSeo?.localSeoIds[0]};
            } else return item;
          });
          if (!response?.layout?.length) {
            const layout = getDefaultLayoutWithCheckedSections(response, response.layoutType || 'default');
            response.layout = layout;
          }
          self.summaryOverview = '';
          self.singleProjectData = cast(response);
          setLayoutType(response?.layoutType);
          setBackupLayout({layout: response?.layout, customLayout: response?.customLayout, layoutType: response?.layoutType});
        }
        return response;
      } catch (e) {
        self.loadingSingleProjectData = false;
        useErrorNotification({
          e,
          msg: apiError(e) as string,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Something went wrong',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
          ],
        });
        return Promise.reject(e);
      } finally {
        self.loadingSingleProjectData = false;
      }
    });

    const loadAISummaries = flow(function* (id) {
      self.loadingAiSummary = true;
      let response;
      try {
        if (id) {
          response = yield REPORT_BUILDER_API.loadAISummaries(id, publicHash());
          self.singleProjectData = self.singleProjectData ? cast({...self.singleProjectData, aiSummary: response.aiSummary}) : response;
        }
      } catch (e) {
        self.loadingSingleProjectData = false;
        return Promise.reject(e);
      } finally {
        loadAISummariesOverview(id);
      }
    });

    const loadAISummariesOverview = flow(function* (id) {
      self.loadingAiSummaryOverview = true;
      let response;
      try {
        if (id) {
          response = yield REPORT_BUILDER_API.loadAISummariesOverview(id, publicHash());
          self.summaryOverview = response.aiSummary?.reportSummary;
        }
      } catch (e) {
        self.loadingSingleProjectData = false;
        return Promise.reject(e);
      } finally {
        self.loadingAiSummaryOverview = false;
        self.loadingAiSummary = false;
      }
    });


    const loadReportBuilderKrtList = flow(function* (noLoading?: boolean) {
      if (!noLoading) {
        self.loadingKrtList = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.loadReportBuilderKrtListData(1, false, false, publicHash());
        self.reportsKrtList = cast(response?.results);
        if (response.results) {
          self.loadingKrtList = false;
        }
        return response;
      } catch (e) {
        self.loadingKrtList = false;
        return Promise.reject(e);
      }
    });
    const loadReportBuilderSeoList = flow(function* (noLoading?: boolean) {
      if (publicHash()) return;
      if (!noLoading) {
        self.loadingSeoList = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.loadReportBuilderSeoList();
        self.reportsSeoList = cast(response?.results);
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingSeoList = false;
      }
    });
    const loadReportBuilderSeoProjectDetail = flow(function* (id: number, noLoading?: boolean) {
      if (publicHash()) return;
      if (!noLoading) {
        self.loadingSeoProjectDetail = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.loadReportBuilderSeoProjectDetail(id);
        self.reportsSeoProjectDetail = cast(response);
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingSeoProjectDetail = false;
      }
    });
    const loadLocalSeoList = flow(function* () {
      try {
        self.loadingLocalSeoDetailRb = true;
        const response = yield REPORT_BUILDER_API.loadLocalSeoList({public_hash: publicHash()});
        self.reportsLocalSeoList = cast(response);
        self.loadingLocalSeoDetailRb = false;
        return response;
      } catch (e) {
        self.loadingLocalSeoDetailRb = false;
        return Promise.reject(e);
      } finally {
        self.loadingLocalSeoDetailRb = false;
      }
    });
    const loadReportBuilderGSCList = flow(function* (countryCode: string, noLoading?: boolean) {
      if (publicHash()) return;
      if (!noLoading) {
        self.loadingGSCList = true;
      }
      const params = {
        country_code: countryCode,
      };
      try {
        const response = yield REPORT_BUILDER_API.loadReportBuilderGSCListData(params, publicHash());
        const addedNoneToEachObjcountryCodes = response?.map(item => {
          return {...item, countryCodes: [...item.countryCodes, 'none']};
        });
        self.reportsGSCList = cast(addedNoneToEachObjcountryCodes);
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingGSCList = false;
      }
    });
    const createNotionBoard = flow(function* (payload, noLoading?: boolean) {
      if (!noLoading) {
        self.creatingNotionBoard = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.createNotionBoard(payload);
        notification.success('Success', 'Board created successfully');
        return response;
      } catch (e) {
        useErrorNotification({
          e,
          msg: apiError(e) as string,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Something went wrong',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
            {
              statuses: [404],
              msg: 'Something went wrong',
              showDetails: false,
            },
          ],
        });
        return Promise.reject(e);
      } finally {
        self.creatingNotionBoard = false;
      }
    });
    const addTask = flow(function* (payload, noLoading?: boolean) {
      if (!noLoading) {
        self.addingNotionBoardTask = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.createTask(payload);
        notification.success('Success', 'Task added successfully');
        return response;
      } catch (e) {
        useErrorNotification({
          e,
          msg: apiError(e) as string,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Something went wrong',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
            {
              statuses: [404],
              msg: 'Something went wrong',
              showDetails: false,
            },
          ],
        });
        return Promise.reject(e);
      } finally {
        self.addingNotionBoardTask = false;
      }
    });

    const setAddTaskBoard = value => self.addTaskBoard = value;

    const loadBoardsList = flow(function* (customerClientid: number, noLoading?: boolean) {
      if (!noLoading) {
        self.loadingBoardsList = true;
      }
      try {
        const response = yield REPORT_BUILDER_API.boardsListAPI(customerClientid);
        self.boardsList = cast(response.data);
        return response.data;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingBoardsList = false;
      }
    });
    const getFormatedDate = (key, date) => {
      switch (key) {
        case 'oneMonth':
          self.periodStart = moment(date).subtract(1, 'months').format('MMM DD, YYYY');
          return moment(date).subtract(1, 'months').format('YYYY-MM-DD');
        case 'threeMonth':
          self.periodStart = moment(date).subtract(3, 'months').format('MMM DD, YYYY');
          return moment(date).subtract(3, 'months').format('YYYY-MM-DD');
        case 'sixMonth':
          self.periodStart = moment(date).subtract(6, 'months').format('MMM DD, YYYY');
          return moment(date).subtract(6, 'months').format('YYYY-MM-DD');
        case 'oneYear':
          self.periodStart = moment(date).subtract(1, 'years').format('MMM DD, YYYY');
          return moment(date).subtract(1, 'years').format('YYYY-MM-DD');
        default:
          self.periodStart = moment(date).subtract(1, 'years').format('MMM DD, YYYY');
          return moment(date).subtract(1, 'years').format('YYYY-MM-DD');
      }
    };
    const loadGADetailsData = flow(function* (gaPropertyId: string, countryCode: string, dateRange: any, selectedMertics: string[], noLoading?: boolean) {
      if (!gaPropertyId || gaPropertyId == 'Select Project') {
        self.reportGADetailData = null;
      } else {
        if (!noLoading) {
          self.loadingGADetail = true;
        }
        self.periodEnd = typeof dateRange == 'string' ? moment()?.format('MMM DD, YYYY') : dateRange?.endDate || moment()?.format('YYYY-MM-DD');
        const selectedMetricsStr = selectedMertics?.join(',');
        const params = {
          'ga_property_id': gaPropertyId,
          'country_code': countryCode,
          'period_start': typeof dateRange == 'string' ? getFormatedDate(dateRange, moment()?.format('YYYY-MM-DD')) : dateRange?.startDate || moment()?.format('YYYY-MM-DD'),
          'period_end': typeof dateRange == 'string' ? moment()?.format('YYYY-MM-DD'): dateRange?.endDate || moment()?.format('YYYY-MM-DD'),
          'reports': selectedMertics?.join(','),
          ...(publicHash() && {public_share_hash: publicHash()}),
        };
        if (selectedMertics?.length == 1) {
          if (selectedMetricsStr == 'source_medium') {
            params['order_bys'] = self.topUsersParams?.ordering || null;
            params['page_number'] = self.topUsersParams?.page || null;
            params['page_size'] = self.topUsersParams?.page_size*2 || null;
            if (publicHash()) params['public_share_hash'] = publicHash();
          } else if (selectedMetricsStr == 'key_events') {
            params['page_number'] = self.keyEventParams?.page || null;
            params['page_size'] = self.keyEventParams?.page_size || null;
            params['order_bys'] = self.keyEventParams?.ordering || null;
            if (publicHash()) params['public_share_hash'] = publicHash();
          } else {
            params['page_number'] = self.topPagesParams?.page || null;
            params['page_size'] = self.topPagesParams?.page_size || null;
            params['order_bys'] = self.topPagesParams?.ordering || null;
            if (publicHash()) params['public_share_hash'] = publicHash();
          }
        }
        try {
          const response = yield REPORT_BUILDER_API.loadGADetails(params);
          response['totalUsersTrend'] = response.usersTrend;
          // self.total = response?.count;
          // self.reportsGAList = cast(response || []);
          const newData = {
            ...toJS(self.reportGADetailData),
            ...response,
          };
          newData['channels'] = newData.channels?.sort().reverse();
          self.reportGADetailData = cast(newData);
          // const metricsStatus = response?.results?.filter(item => item?.status == 'PENDING');
          // if (metricsStatus?.length && self.listApiRepolling) {
          //   yield new Promise(r => setTimeout(r, 2000));
          //   return loadReportBuilderList(true);
          // }
          self.loadingGADetail = false;
        } catch (e) {
          self.loadingGADetail = false;
          useErrorNotification({
            e,
            msg: apiError(e) as string,
            desc: '',
            permanent: false,
            handleStatuses: [
              {
                statuses: [401, 403],
                msg: 'Something went wrong',
                permanent: false,
                showDetails: false,
              },
              {
                statuses: [400],
                msg: apiError(e) as string,
                ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
                showDetails: false,
              },
              {
                statuses: [404],
                msg: apiError(e) as string == 'Not found.' ? 'Your selected Google account/project has been disconnected or deleted.' : apiError(e) as string,
                showDetails: false,
              },
            ],
          });
          return Promise.reject(e);
        } finally {
          self.topUsersLoading = false;
          self.topPagesLoading = false;
          self.keyEventLoading = false;
        }
      }
    });

    const setDefaultMap = (data:any) => self.defaultMap = data;
    const removeStoreData = section => {
      if (section == 'ga') {
        self.reportGADetailData = null;
      }
      if (section == 'gsc') {
        self.reportGSCDetailsData = null;
      }
      if (section == 'krt') {
        self.reportKrtDetailData = null;
      }
      if (section == 'seo') {
        self.localSeoDetailsData = null;
      }
      if (section == 'gmb') {
        self.gmbReportData = null;
        self.gmbReportDataRB = null;
      }
      if (section == 'fb') {
        self.reportsFbListUser = null;
        self.reportsFbListAge = null;
        self.reportsFbListGender = null;
      }
      if (section == 'gl') {
        self.reportsGoogleListUser = null;
        self.reportsGoogleListMatrix = null;
        self.reportsGoogleListOverTime = null;
        self.reportsGoogleListByCampaign = null;
      }
    };

    const loadReportBuilderBacklinksList = flow(function* (noLoading?: boolean, isLoadMore?: boolean) {
      if (!noLoading) {
        self.loadingBacklinksList = true;
      }
      try {
        const params = {
          ...self.siteExplorerParams,
          saved: 1,
          ...(publicHash() && {hash: publicHash()}),
        };
        const response = yield REPORT_BUILDER_API.loadReportBuilderBacklinksListData(params);
        self.siteExplorerCount = response?.count;
        if (isLoadMore) {
          self.reportsBacklinksList.push(...cast(response?.results));
        } else {
          self.reportsBacklinksList = cast(response?.results);
        }
        if (response?.results) {
          self.loadingBacklinksList = false;
        }
        return response?.results;
      } catch (e) {
        self.loadingBacklinksList = false;
        return Promise.reject(e);
      }
    });
    const loadBacklinksDetailsData = flow(function* (targetId, noLoading?: boolean, selectedRange?: any) {
      if (!targetId || targetId == 'Select Project') {
        self.reportBacklinksDetailData = null;
      } else {
        if (!noLoading) {
          self.loadingBacklinksDetail = true;
        }
        const date = selectedRange ? {'period_start': typeof selectedRange == 'string' ? getFormatedDate(selectedRange, moment()?.format('YYYY-MM-DD')) : selectedRange?.startDate || moment()?.format('YYYY-MM-DD'),
          'period_end': typeof selectedRange == 'string' ? moment()?.format('YYYY-MM-DD') : selectedRange?.endDate || moment()?.format('YYYY-MM-DD')} : {};
        const params = {
          'target': targetId,
          ...date,
          ...(publicHash() && {hash: publicHash()}),
        };
        try {
          const response = yield REPORT_BUILDER_API.loadBacklinksDetails(params);
          if (response) {
            self.reportBacklinksDetailData = cast(response);
          } else {
            self.reportBacklinksDetailData = null;
          }
          self.loadingBacklinksDetail = false;
        } catch (e) {
          self.loadingBacklinksDetail = false;
          self.reportBacklinksDetailData = null;
          return Promise.reject(e);
        }
      }
    });
    const loadLocalSeoDetailsData = flow(function* (id, data, noLoading?: boolean) {
      if (!noLoading) {
        self.loadingLocalSeoDetail = true;
      }
      data.date = typeof data.date == 'string' ? data?.date?.includes('-') ? data.date : getFormatedDate(data?.date, moment()?.format('YYYY-MM-DD')) : data.date?.startDate || moment()?.format('YYYY-MM-DD');
      self.periodEnd = moment(data?.date)?.format('MMM DD, YYYY');
      const params = {
        keyword: data?.keyword,
        date: data?.date,
        dates: data?.date,
        ...(publicHash() && {hash: publicHash()}),
      };
      try {
        let response;
        const index = self.localSeoDetailsData && toJS(self.localSeoDetailsData).findIndex(item => item.id === id);
        if (index == -1 || self.localSeoDetailsData == null) {
          // If the id exists, replace the existing data
          response = yield REPORT_BUILDER_API.loadLocalSeoDetails(id, params);
          if (response) {
            const resTimekey = Object.keys(response)?.[0];
            const newData = toJS(self.localSeoDetailsData) ?? [];
            newData.push({id: id, rbSeoData: resTimekey ? response[resTimekey] : response});
            self.localSeoDetailsData = cast(newData);
          }
        }
        self.loadingLocalSeoDetail = false;
        return response;
      } catch (e) {
        self.loadingLocalSeoDetail = false;
        self.localSeoDetailsData = null;
        useErrorNotification({
          e,
          msg: apiError(e) as string,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Something went wrong',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
          ],
        });
        return Promise.reject(e);
      } finally {
        self.loadingLocalSeoDetail = false;
      }
    });

    const loadLocalSeoDetailsDataVOne = flow(function* (id, data, noLoading?: boolean) {
      if (!id || id == 'Select Project') {
        self.localSeoDetailsData = null;
      } else {
        if (!noLoading) {
          self.loadingLocalSeoDetail = true;
        }
        data.date = data?.date?.includes('-') ? data.date : getFormatedDate(data?.date, moment()?.format('YYYY-MM-DD'));
        self.periodEnd = moment(data?.date)?.format('MMM DD, YYYY');
        const params = {
          keyword: data?.keyword,
          date: data?.date,
          dates: [data?.date],
          ...(publicHash() && {hash: publicHash()}),
        };
        try {
          const response = yield REPORT_BUILDER_API.loadLocalSeoDetails(id, params);
          if (response) {
            self.localSeoDetailsData = cast([{id: id, rbSeoData: response}]);
          } else {
            self.localSeoDetailsData = null;
          }
          self.loadingLocalSeoDetail = false;
          return response;
        } catch (e) {
          self.loadingLocalSeoDetail = false;
          self.localSeoDetailsData = null;
          return Promise.reject(e);
        }
      }
    });

    const loadLocalSeoDetailsDataList = flow(function* (id, data, noLoading?: boolean) {
      if (!id || id == 'Select Project') {
        self.localSeoDetailsData = null;
      } else {
        if (!noLoading) {
          self.loadingLocalSeoDetail = true;
        }
        data.date = data?.date?.includes('-') ? data.date : getFormatedDate(data?.date, moment()?.format('YYYY-MM-DD'));
        const params = {
          keyword: data?.keyword,
          dates: `${[data?.date]}`,
          ...(publicHash() && {hash: publicHash()}),
        };
        try {
          const response = yield REPORT_BUILDER_API.loadLocalSeoDetails(id, params);
          self.loadingLocalSeoDetail = false;
          return response;
        } catch (e) {
          self.loadingLocalSeoDetail = false;
          self.localSeoDetailsData = null;
          return Promise.reject(e);
        }
      }
    });

    const loadDataForBacklinksTable = flow(function* (data, noLoading?: boolean) {
      if (publicHash()) return;
      if (!noLoading) {
        self.loadingTableData = true;
      }
      self.periodEnd = moment()?.format('MMM DD, YYYY');
      const params = {
        period_start: getFormatedDate(data?.date, moment()?.format('YYYY-MM-DD')),
        url: data?.url,
        include_all_time_orders: data?.includeAllTimeOrders,
      };
      try {
        const response = yield REPORT_BUILDER_API.loadDataForBacklinksTable(params);
        if (response) {
          self.backlinksDataForTable = cast(response);
        }
        self.loadingTableData = false;
      } catch (e) {
        self.loadingTableData = false;
        return Promise.reject(e);
      }
    });
    const loadKrtDetailsData = flow(function* (targetId, dateRange, noLoading?: boolean, sortBy?: string) {
      if (!targetId || targetId == 'Select Project') {
        self.reportKrtDetailData = null;
      } else {
        self.loadingKrtTableDetail = true;
        if (!noLoading) {
          self.loadingKrtDetail = true;
        }
        const postProcessingUpdatedAt = self.getReportsKrtList?.find(e => e?.id == targetId)?.postProcessingUpdatedAt;
        self.periodEnd = typeof dateRange == 'string' ? postProcessingUpdatedAt ? postProcessingUpdatedAt?.split('T')[0] || moment(postProcessingUpdatedAt)?.format('MMM DD, YYYY') : moment()?.format('MMM DD, YYYY') : dateRange?.endDate || moment()?.format('YYYY-MM-DD');
        const params = {
          sort_by: sortBy,
          period2_start: typeof dateRange == 'string' ? getFormatedDate(dateRange, postProcessingUpdatedAt ? postProcessingUpdatedAt?.split('T')[0] || moment(postProcessingUpdatedAt)?.format('YYYY-MM-DD') : moment()?.format('YYYY-MM-DD')) : dateRange?.startDate || moment()?.format('YYYY-MM-DD'),
          period2_end: typeof dateRange == 'string' ? postProcessingUpdatedAt ? postProcessingUpdatedAt?.split('T')[0] || moment(postProcessingUpdatedAt)?.format('YYYY-MM-DD') : moment()?.format('YYYY-MM-DD') : dateRange?.endDate || moment()?.format('YYYY-MM-DD'),
          ...toJS(self.krtTableParams),
          ...(publicHash() && {hash: publicHash()}),
        };
        try {
          const response = yield REPORT_BUILDER_API.loadKrtDetails(targetId, params);
          if (response) {
            self.reportKrtDetailData = cast(response);
          } else {
            self.reportKrtDetailData = null;
          }
          self.loadingKrtTableDetail = false;
          self.loadingKrtDetail = false;
        } catch (e) {
          self.loadingKrtTableDetail = false;
          self.loadingKrtDetail = false;
          self.reportKrtDetailData = null;
          useErrorNotification({
            e,
            msg: apiError(e) as string,
            desc: '',
            permanent: false,
            handleStatuses: [
              {
                statuses: [401, 403],
                msg: 'Something went wrong',
                permanent: false,
                showDetails: false,
              },
              {
                statuses: [400],
                msg: apiError(e) as string,
                ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
                showDetails: false,
              },
              {
                statuses: [404],
                msg: apiError(e) as string == 'Not found.' ? 'Your selected KRT project has been deleted or doesn\'t exist.' : apiError(e) as string,
                showDetails: false,
              },
            ],
          });
          return Promise.reject(e);
        } finally {
          self.loadingKrtTableDetail = false;
          self.loadingKrtDetail = false;
        }
      }
    });
    const loadGSCDetailsData = flow(function* (gaPropertyId: string, countryCode: string, dateRange: any, selectedMertics: string[], noLoading?: boolean) {
      if (!gaPropertyId || gaPropertyId == 'Select Project') {
        self.reportGSCDetailsData = null;
      } else {
        if (!noLoading) {
          self.loadingGSCDetail = true;
        }
        self.periodEnd = typeof dateRange == 'string' ? moment()?.format('MMM DD, YYYY') : dateRange?.endDate || moment()?.format('YYYY-MM-DD');
        const params = {
          'gsc_property_url': gaPropertyId,
          'country_code': countryCode,
          'period_start': typeof dateRange == 'string' ? getFormatedDate(dateRange, moment()?.format('YYYY-MM-DD')) : dateRange?.startDate || moment()?.format('YYYY-MM-DD'),
          'period_end': typeof dateRange == 'string' ? moment()?.format('YYYY-MM-DD') : dateRange?.endDate || moment()?.format('YYYY-MM-DD'),
          'reports': selectedMertics?.join(','),
          'page_number': self.topKeywordsParams?.page,
          'page_size': self.topKeywordsParams?.page_size,
          ...(selectedMertics?.length == 1 && self.topKeywordsParams?.ordering && {'order_bys': self.topKeywordsParams?.ordering}),
          ...(publicHash() && {public_share_hash: publicHash()}),
        };
        try {
          const response = yield REPORT_BUILDER_API.loadGSCDetails(params);
          self.reportGSCDetailsData = cast({
            ...toJS(self.reportGSCDetailsData),
            ...response,
          });
        } catch (e) {
          self.reportGSCDetailsData = null;
          useErrorNotification({
            e,
            msg: 'Something went wrong.',
            desc: '',
            permanent: false,
            handleStatuses: [
              {
                statuses: [401],
                msg: 'Something went wrong',
                permanent: false,
                showDetails: false,
              },
              {
                statuses: [400],
                msg: apiError(e) as string,
                ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
                showDetails: false,
              },
              {
                statuses: [403],
                msg: apiError(e) as string,
                desc: e?.response?.data?.info,
                showDetails: false,
              },
              {
                statuses: [404],
                msg: apiError(e) as string == 'Not found.' ? 'Your selected GSC project has been deleted or doesn\'t exist.' : apiError(e) as string,
                showDetails: false,
              },
            ],
          });
          return Promise.reject(e);
        } finally {
          self.loadingGSCDetail = false;
          self.topKeywordsLoading = false;
        }
      }
    });

    const loadGMBData = flow(function* () {
      self.loadingGmbList = true;
      try {
        const response = yield REPORT_BUILDER_API.loadGMBList(publicHash());
        self.GMBList = response;
        return response;
      } catch (error) {
        const errorMessage = apiError(error) as string;
        notification.error('', errorMessage, false, 'OK');
      } finally {
        self.loadingGmbList = false;
      }
    });

    const loadGMBReportDataRB = flow(function* (params, gmbIds) {
      self.loadingGMBReportData = true;
      if (publicHash()) {
        params['public_share_hash']= publicHash();
      }
      try {
        const response = yield REPORT_BUILDER_API.loadGmbReport(params, gmbIds);
        const data = toJS(self.gmbReportDataRB)?.length ? [...(toJS(self.gmbReportDataRB))] : [];
        data.push({id: gmbIds, businessTitle: response.businessTitle, value: response});
        self.gmbReportDataRB = cast(data);
        return response;
      } catch (e) {
        const errorMessage = apiError(e) as string;
        useErrorNotification({
          e,
          msg: errorMessage,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [404],
              msg: apiError(e) as string == 'Not found.' ? 'Your selected GMB project has been deleted or doesn\'t exist.' : apiError(e) as string,
              showDetails: false,
            },
          ],
        });
      } finally {
        self.loadingGMBReportData = false;
      }
    });

    const loadGMBReportData = flow(function* (params, gmbIds) {
      self.loadingGMBReportData = true;
      if (publicHash()) {
        params['public_share_hash']= publicHash();
      }
      try {
        const response = yield REPORT_BUILDER_API.loadGmbReport(params, gmbIds);
        self.gmbReportData = response;
        return response;
      } catch (error) {
        const errorMessage = apiError(error) as string;
        notification.error('', errorMessage, false, 'OK');
      } finally {
        self.loadingGMBReportData = false;
      }
    });

    const setLoadingDetail = val => {
      self.loadingGADetail = val;
    };
    const setLoadingGSCDetail = val => {
      self.loadingGSCDetail = val;
    };
    const setLoadingBacklinksDetail = val => {
      self.loadingBacklinksDetail = val;
    };
    const setLoadingSeoDetail = val => {
      self.loadingLocalSeoDetail = val;
    };
    const resetGSCDetailsData = () => {
      self.reportGSCDetailsData = null;
    };
    const setLoadingKrtDetail = value => {
      self.loadingKrtDetail = value;
    };
    const resetGADetailsData = () => {
      self.reportGADetailData = null;
    };
    const resetBacklinksDetailsData = () => {
      self.reportBacklinksDetailData = null;
    };
    const resetKrtDetailsData = () => {
      self.reportKrtDetailData = null;
    };
    const updateProgressBar = (current, total) => {
      const progress = (current / total) * 100;
      self.reportBuilderPdfBar = Math.round(progress);
    };
    const resetSeoDetailsData = () => {
      self.localSeoDetailsData = null;
    };
    const getDateForExport = key => {
      switch (key) {
        case 'oneMonth':
          return moment().subtract(1, 'months').format('MMM DD, YYYY');
        case 'threeMonth':
          return moment().subtract(3, 'months').format('MMM DD, YYYY');
        case 'sixMonth':
          return moment().subtract(6, 'months').format('MMM DD, YYYY');
        case 'oneYear':
          return moment().subtract(1, 'years').format('MMM DD, YYYY');
        default:
          return moment().subtract(1, 'years').format('YYYY-MM-DD');
      }
    };

    const getDateRangeObj = (date: string) => {
      switch (date) {
        case 'One month':
          return 'Last 1 month';
        case 'Three month':
          return 'Last 3 months';
        case 'Six month':
          return 'Last 6 months';
        case 'One year':
          return 'Last 1 year';
        default:
          return date;
      }
    };

    const dateRangeObj = {
      oneMonth: 'Last 1 month',
      threeMonth: 'Last 3 months',
      sixMonth: 'Last 6 months',
      oneYear: 'Last 1 year',
    };
    const exportSingleNode = flow(function* (node, data?: any, isReportBuilderV2?: boolean) {
      self.isExporting = true;
      try {
        const date = moment()?.format('MMM, DD YYYY');
        // yield exportSingleNodeMultiplePages(node, `report-builder-${date}.pdf`);
        const {scrollWidth, scrollHeight} = node;
        const maxCanvasHeight = 8000;
        const pdf = new Jspdf({
          orientation: 'p',
          unit: 'pt',
          format: [scrollWidth, Math.min(maxCanvasHeight, scrollHeight)],
        });

        for (let y = 0; y < scrollHeight; y += maxCanvasHeight) {
          const height = Math.min(maxCanvasHeight, scrollHeight - y);
          const imageData = yield captureSection(node, y, height);
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgProps= pdf.getImageProperties(imageData);
          const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

          pdf.addImage(imageData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST', 0);
          if (y + height < scrollHeight) {
            pdf.addPage([scrollWidth, (scrollHeight - imgHeight) > maxCanvasHeight ? maxCanvasHeight : scrollHeight - imgHeight], 'p');
          }
          updateProgressBar(y + height, scrollHeight);
        }
        pdf.save(`${data?.domain ? `${data?.domain} - ${data?.country || 'Worldwide'} - ${data?.isLocalSeo ? moment(data?.date)?.format('MMM, DD YYYY') : `${isReportBuilderV2 ? getDateRangeObj(data?.date) : dateRangeObj[data?.date]}(${getDateForExport(data?.date)} to ${date})`}` : `report-builder-${date}`}.pdf`);
      } catch (error) {
        AntdNotification.error({message: `Error during export.`});
        updateProgressBar(1, 100);
        self.isExporting = false;
      } finally {
        self.isExporting = false;
        updateProgressBar(1, 100);
      }
    });

    const exportSingleNodeApi = flow(function* (node, data, isReportBuilderV2) {
      try {
        self.isExporting = true;
        yield new Promise(r => setTimeout(r, 500));
        yield exportSingleNode(node, data, isReportBuilderV2);
      } catch (e) {
        self.isExporting = false;
      }
    });

    const setTopKeywordsParams = (gaPropertyId: string, countryCode: string, dateRange: any, paginationParams?: any, noApiCall?: boolean) => {
      self.topKeywordsParams = paginationParams;
      if (!noApiCall) {
        self.topKeywordsLoading = true;
        loadGSCDetailsData(gaPropertyId, countryCode, dateRange, ['top_keywords'], true);
      }
    };
    const setKrtTableParams = (gaPropertyId: string, dateRange: string, paginationParams?: any, noApiCall?: boolean) => {
      self.krtTableParams = paginationParams;
      if (!noApiCall) {
        self.loadingKrtTableDetail = true;
        loadKrtDetailsData(gaPropertyId, dateRange, true);
      }
    };
    const setTopUsersParams = (gaPropertyId: string, countryCode: string, dateRange: string, paginationParams?: any, noApiCall?: boolean, section?: any) => {
      self.topUsersParams = paginationParams;
      if (!noApiCall) {
        self.topUsersLoading = true;
        loadGADetailsData(gaPropertyId, countryCode, dateRange, section ? section : ['source_medium'], true);
      }
    };
    const setTopPagesParams = (gaPropertyId: string, countryCode: string, dateRange: any, paginationParams?: any, noApiCall?: boolean) => {
      self.topPagesParams = paginationParams;
      if (!noApiCall) {
        self.topPagesLoading = true;
        loadGADetailsData(gaPropertyId, countryCode, dateRange, ['top_pages'], true);
      }
    };

    const setKeyEventsParams = (gaPropertyId: string, countryCode: string, dateRange: any, paginationParams?: any, noApiCall?: boolean) => {
      self.keyEventParams = paginationParams;
      if (!noApiCall) {
        self.keyEventLoading = true;
        loadGADetailsData(gaPropertyId, countryCode, dateRange, ['key_events'], true);
      }
    };

    const setIsConfig = (value: boolean) => {
      self.isConfig = value;
    };

    const setIsSeoUpdate = (value: boolean) => {
      self.isSeoUpdate = value;
    };

    const setSummaryHeight = (value: number) => {
      self.summaryHeight = value;
    };

    const setAllRender = (value: any) => {
      self.isAllRender = value;
    };
    const setBtnDisableOnLoading = (value: any) => {
      self.btnDisabledOnLoading = value;
    };

    const setIsConfirmModalVisible = (value: boolean) => {
      self.isConfirmModalVisible = value;
    };
    const setLayoutType = (value: any) => {
      self.layoutType = value;
    };

    const setLocalSeoLoading = (value: any) => {
      self.localSeoLoading = value ? value : false;
    };

    const loadgetFacebookAdsTableData = flow(function* (params) {
      self.loadingFb = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getFacebookAdsTableData(newParams);
        if (params?.next_page) {
          const newRes = {
            data: self.reportsFbListUser['data']?.length > 0 ? [...self.reportsFbListUser['data'], ...response.data] : [...response.data],
            total: self.reportsFbListUser['total'],
            nextPageToken: response['nextPageToken'],
          };
          self.reportsFbListUser = cast({...newRes});
        } else {
          self.reportsFbListUser = response;
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingFb = false;
      }
    });

    const loadgetGoogleAdsTableData = flow(function* (params) {
      self.loadingglTable = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getGoogleAdsTableData(newParams);

        if (params?.next_page) {
          const newRes = {
            data: {
              results: self.reportsGoogleListUser['data']['results']?.length > 0 ? [...self.reportsGoogleListUser['data']['results'], ...response.data.results] : [...response.data.results],
              total: self.reportsGoogleListUser['data']['total'],
              nextPageToken: response['data']['nextPageToken'],
            },
          };
          self.reportsGoogleListUser = cast({...newRes});
        } else {
          self.reportsGoogleListUser = response;
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingglTable = false;
      }
    });

    const setActiveTabKey = value => self.activeTabKey = value;

    const loadgetGoogleAdsMatrixData = flow(function* (params) {
      self.loadingglMatrix = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getGoogleAdsMatrixData(newParams);
        self.reportsGoogleListMatrix = response;
      } catch (e) {
        useErrorNotification({
          e,
          msg: apiError(e) as string,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Connect to google ads account again in the report configuration',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
          ],
        });
        return Promise.reject(e);
      } finally {
        self.loadingglMatrix = false;
      }
    });

    const loadgetGoogleAdsOverTimeData = flow(function* (params) {
      self.loadingglgraph = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getGoogleAdsOverTimeData(newParams);
        self.reportsGoogleListOverTime = response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingglgraph = false;
      }
    });

    const loadgetGoogleAdsByCampaign = flow(function* (params) {
      self.loadingglgraph = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getGoogleAdsByCampaign(newParams);
        self.reportsGoogleListByCampaign = response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingglgraph = false;
      }
    });

    const loadgetFacebookAdSetData = flow(function* (params) {
      self.loadingFb = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getFacebookAdsAdset(newParams);
        if (params?.next_page) {
          const newRes = {
            data: self.reportsFbListUser['data']?.length > 0 ? [...self.reportsFbListUser['data'], ...response.data] : [...response.data],
            total: self.reportsFbListUser['total'],
            nextPageToken: response['nextPageToken'],
          };
          self.reportsFbListUser = cast({...newRes});
        } else {
          self.reportsFbListUser = response;
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingFb = false;
      }
    });

    const loadgetFacebookAdsData = flow(function* (params) {
      self.loadingFb = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getFacebookAdsAds(newParams);
        if (params?.next_page) {
          const newRes = {
            data: self.reportsFbListUser['data']?.length > 0 ? [...self.reportsFbListUser['data'], ...response.data] : [...response.data],
            total: self.reportsFbListUser['total'],
            nextPageToken: response['nextPageToken'],
          };
          self.reportsFbListUser = cast({...newRes});
        } else {
          self.reportsFbListUser = response;
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingFb = false;
      }
    });

    const loadgetFacebookAdsGender = flow(function* (params) {
      self.loadingFbGender = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getFacebookAdsAdGender(newParams);
        self.reportsFbListGender = response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingFbGender = false;
      }
    });

    const loadgetFacebookAdsAge = flow(function* (params) {
      self.loadingFbAge = true;
      try {
        const publicHash = getSingleUrlParam('public_hash');
        let newParams = null;
        if (publicHash) {
          newParams = {...params, public_share_hash: publicHash};
        } else {
          newParams = params;
        }
        const response = yield REPORT_BUILDER_API.getFacebookAdsAdGender(newParams);
        self.reportsFbListAge = response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingFbAge = false;
      }
    });

    const loadFbPublisherAccount = flow(function* (params) {
      try {
        const response = yield REPORT_BUILDER_API.getFbPublisherAccount(params);
        self.reportsFbPublisherUser = response;
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingBacklinksList = false;
      }
    });

    const loadFbLineChartAccount = flow(function* (params) {
      try {
        const response = yield REPORT_BUILDER_API.getFbLineChartAccount(params);
        self.reportsFbLineChart = response;
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingBacklinksList = false;
      }
    });

    const setGraphDataRb = value => self.graphDataRb = value;

    const loadFbBarChartAccount = flow(function* (params) {
      try {
        const response = yield REPORT_BUILDER_API.getFbBarChartAccount(params);
        self.reportsFbBarChart = response;
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingBacklinksList = false;
      }
    });

    const loadFbUserAccount = flow(function* () {
      try {
        self.loadingFb = true;
        const response = yield REPORT_BUILDER_API.getFbUserAdAccount();
        self.reportsFbList = cast(response);
        // self.siteExplorerCount = response?.count;
        // self.reportsBacklinksList = cast(response?.results);
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingFb = false;
      }
    });

    const loadGoogleAdUserAccount = flow(function* () {
      try {
        self.glLoader = true;
        const response = yield REPORT_BUILDER_API.getGoogleAdUserAdAccount();
        self.reportsGoogleAdList = cast(response?.filter(item=> item.descriptiveName));
        // self.siteExplorerCount = response?.count;
        // self.reportsBacklinksList = cast(response?.results);
        if (response.length) {
          self.glLoader = false;
        }
        return response?.filter(item=> item.descriptiveName);
      } catch (e) {
        self.glLoader = false;
        useErrorNotification({
          e,
          msg: apiError(e) as string,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Something went wrong!',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
          ],
        });
        return Promise.reject(e);
      }
    });

    const setModalData = data => {
      self.modalData = data;
    };

    const facebookAdsDisconnects = flow(function* (email) {
      try {
        self.fbLoader = true;
        const response = yield REPORT_BUILDER_API.facebookAdDisconnect({email: email});
        return response;
      } catch (e) {
        useErrorNotification({
          e,
          msg: apiError(e) as string,
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Something went wrong!',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
          ],
        });
        return Promise.reject(e);
      } finally {
        self.fbLoader = false;
      }
    });

    const loadCustomTemplate = flow(function* (data=null) {
      try {
        const response = yield REPORT_BUILDER_API.loadCustomTemplate(data);
        if (response) {
          self.layouts = response;
        }
      } catch (e) {
        const errorMessage = apiError(e) as string;
        notification.error('Failed', errorMessage ?? 'Failed to Fetch Custom Templates');
        return Promise.reject(e);
      }
    });

    const createCustomTemplate = flow(function* (data) {
      try {
        const response = yield REPORT_BUILDER_API.createCustomTemplate(data);
        if (response) {
          notification.success('Success!', 'Custom Template Created Successfully');
          return response;
        }
      } catch (e) {
        const errorMessage = apiError(e) as string;
        notification.error('Failed', errorMessage ?? 'Failed to Create Custom Template');
        return Promise.reject(e);
      }
    });

    const updateCustomTemplate = flow(function* (id, data) {
      try {
        const response = yield REPORT_BUILDER_API.updateCustomTemplate(id, data);
        if (response) {
          notification.success('Success!', 'Custom Template Updated Successfully');
        }
      } catch (e) {
        const errorMessage = apiError(e) as string;
        notification.error('Failed', errorMessage ?? 'Failed to Update Custom Template');
        return Promise.reject(e);
      }
    });

    const deleteCustomTemplate = flow(function* (id) {
      try {
        yield REPORT_BUILDER_API.deleteCustomTemplate(id);
        notification.success('Success!', 'Custom Template Deleted Successfully');
      } catch (e) {
        const errorMessage = apiError(e) as string;
        notification.error('Failed', errorMessage ?? 'Failed to Deleted Custom Template');
        return Promise.reject(e);
      }
    });

    const setBackupLayout = value => {
      self.backupLayout = value;
    };

    const resetLayoutFromBackup = () => {
      updateSingleProjectData(JSON.parse(JSON.stringify({...self.singleProjectData, layout: self.backupLayout?.layout, customLayout: self.backupLayout?.customLayout, layoutType: self.backupLayout?.layoutType})));
    };

    const setCurrentLayout = value => {
      self.currentLayout = value;
    };

    const checkForChanges = option => {
      const current = self.singleProjectData;
      if (option == 'template') {
        const requiredKeys = ['h', 'w', 'x', 'y', 'name', 'tag', 'value', 'i', 'index'];
        const currentLayout = self.currentLayout?.map(item => {
          const newItem: any = {};
          requiredKeys.forEach(key => {
            newItem[key] = item?.[key];
          });
          return newItem;
        });
        self.layoutState?.lg?.forEach(item => {
          const idx = currentLayout?.findIndex(field => field.index == item.index && field.name == 'customText');
          if (idx != -1) {
            currentLayout[idx].value = item.value;
          }
        });
        const originalLayout = current.layout?.map(item => {
          const newItem = {};
          requiredKeys.forEach(key => {
            newItem[key] = item?.[key];
          });
          return newItem;
        });
        if (JSON.stringify(currentLayout) !== JSON.stringify(originalLayout)) {
          self.showLayoutConfirmModal = {show: true, option};
          return false;
        } else {
          resetChanges({reset: true, option});
        }
      } else if (option == 'tab') {
        const initial = self.backupLayout;

        if (current.layoutType !== initial.layoutType) {
          self.showLayoutConfirmModal = {show: true, option};
          return false;
        }
        if (current.customLayout?.id !== initial?.customLayout?.id) {
          self.showLayoutConfirmModal = {show: true, option};
          return false;
        }
        if (JSON.stringify(current.layout) !== JSON.stringify(initial.layout)) {
          self.showLayoutConfirmModal = {show: true, option};
          return false;
        } else {
          resetChanges({reset: true, option});
        }
      }
    };

    const resetSEOMap = (layout, localSeoIds, seoData = null) => {
      const updatedLayout = JSON.parse(JSON.stringify(layout));
      if (updatedLayout?.length) {
        const layoutIndexes = [];
        updatedLayout?.forEach(item => {
          if (item.tag == 'seo' && !layoutIndexes.includes(item.index.toString())) {
            layoutIndexes.push(item.index.toString());
          }
        });
        if (layoutIndexes?.length) {
          localSeoIds?.forEach((id, i) => {
            if (layoutIndexes[i]) {
              const titleIdx = updatedLayout?.findIndex(item => item.index == layoutIndexes[i] && item.name == 'SeoTitle');
              const mapIdx = updatedLayout?.findIndex(item => item.index == layoutIndexes[i] && item.name == 'Map');
              const updatedSeoData = seoData?.find(item => item.index == id)?.seoData;

              if (titleIdx != -1) {
                updatedLayout[titleIdx].index = id.toString();
              }
              if (mapIdx != -1) {
                updatedLayout[mapIdx].index = id.toString();
                updatedLayout[mapIdx].seoData = updatedSeoData ? updatedSeoData : [];
                updatedLayout[mapIdx].h = 8;
              }
            }
          });
          const fieldDifference = layoutIndexes?.length - localSeoIds?.length;
          if (fieldDifference > 0) {
            layoutIndexes?.slice(localSeoIds?.length)?.forEach(id => {
              const titleIdx = updatedLayout?.findIndex(item => item.index == id && item.name == 'SeoTitle');
              const mapIdx = updatedLayout?.findIndex(item => item.index == id && item.name == 'Map');
              if (titleIdx != -1) {
                updatedLayout.splice(titleIdx, 1);
              }
              if (mapIdx != -1) {
                updatedLayout.splice(mapIdx, 1);
              }
            });
          }
        }
      }
      return updatedLayout;
    };

    const resetChanges = value => {
      self.resetChangesObject = value;
      if (!value?.reset) {
        self.showLayoutConfirmModal = {show: false, option: null};
      }
    };

    return {
      loadgetFacebookAdsTableData,
      loadgetGoogleAdsTableData,
      setActiveTabKey,
      loadgetFacebookAdSetData,
      loadgetGoogleAdsMatrixData,
      loadgetGoogleAdsOverTimeData,
      loadgetGoogleAdsByCampaign,
      loadgetFacebookAdsData,
      facebookAdsDisconnects,
      loadgetFacebookAdsGender,
      loadgetFacebookAdsAge,
      loadFbPublisherAccount,
      loadFbLineChartAccount,
      loadFbBarChartAccount,
      setGraphDataRb,
      loadFbUserAccount,
      loadGoogleAdUserAccount,
      loadReportBuilderGAList,
      setGoogleAdSelect,
      loadReportBuilderOTList,
      setLocalSeoLoading,
      loadReportBuilderBacklinksList,
      removeStoreData,
      setProperty,
      setDefaultMap,
      loadReportBuilderSeoList,
      loadReportBuilderSeoProjectDetail,
      loadReportBuilderGSCList,
      loadReportBuilderKrtList,
      setLoadingSeoDetail,
      exportSingleNode,
      exportSingleNodeApi,
      resetSeoDetailsData,
      loadGSCDetailsData,
      loadBacklinksDetailsData,
      loadGADetailsData,
      setLoadingDetail,
      setLoadingGSCDetail,
      loadKrtDetailsData,
      setLoadingBacklinksDetail,
      loadLocalSeoDetailsData,
      loadLocalSeoDetailsDataList,
      loadLocalSeoDetailsDataVOne,
      resetKrtDetailsData,
      setLoadingKrtDetail,
      resetGADetailsData,
      setTopKeywordsParams,
      setKrtTableParams,
      resetGSCDetailsData,
      resetBacklinksDetailsData,
      setTopUsersParams,
      setTopPagesParams,
      setKeyEventsParams,
      loadDataForBacklinksTable,
      updateProgressBar,
      loadSingleProjectData,
      loadAISummaries,
      loadAISummariesOverview,
      updateSingleProjectData,
      resetProject,
      resetLayout,
      disconnectGa,
      loadLocalSeoList,
      loadGMBData,
      loadGMBReportData,
      loadGMBReportDataRB,
      getGMBReportDataValue,
      setIsConfig,
      setIsSeoUpdate,
      setSummaryHeight,
      setAllRender,
      setBtnDisableOnLoading,
      setSiteExplorerParams,
      setIsConfirmModalVisible,
      setIsLayoutEdit,
      setLayoutType,
      setLayoutState,
      setSeLoading,
      setHideReport,
      createNotionBoard,
      addTask,
      setModalData,
      loadBoardsList,
      setAddTaskBoard,
      createCustomTemplate,
      updateCustomTemplate,
      deleteCustomTemplate,
      loadCustomTemplate,
      resetLayoutFromBackup,
      checkForChanges,
      resetChanges,
      setCurrentLayout,
      resetSEOMap,
      setBackupLayout,
    };
  });


export const initDetailsStore = () => {
  return {
    loadingGADetail: true,
    loadingGSCDetail: true,
    loadingBacklinksDetail: false,
    loadingTableData: true,
    loadingKrtDetail: false,
    loadingSeoList: false,
    loadingSeoProjectDetail: false,
    loadingLocalSeoDetail: false,
    loadingLocalSeoDetailRb: false,
    loadingKrtTableDetail: true,
    loadingGSCList: false,
    loadingBacklinksList: false,
    loadingKrtList: true,
    loadingGAList: false,
    loadingOTList: false,
    isConfig: false,
    isSeoUpdate: false,
    periodStart: moment()?.format('MMM DD, YYYY'),
    periodEnd: moment()?.subtract(1, 'months').format('MMM DD, YYYY'),
    isExporting: false,
    topKeywordsLoading: false,
    topUsersLoading: false,
    topPagesLoading: false,
    keyEventLoading: false,
    topKeywordsParams: {
      page_size: 20,
      page: 1,
      search: '',
      ordering: '',
    },
    reportBuilderPdfBar: 1,
    krtTableParams: {
      page_size: 100,
      page: 1,
      search: '',
      ordering: '',
    },
    topUsersParams: {
      page_size: 5,
      page: 1,
      search: '',
      ordering: '',
    },
    topPagesParams: {
      page_size: 10,
      page: 1,
      search: '',
      ordering: '',
    },
    keyEventParams: {
      page_size: 10,
      page: 1,
      search: '',
      ordering: '',
    },
    loadingGmbList: false,
    googleAdSelect: {name: 'Impressions', value: 1},
    loadingGMBReportData: true,
    resetLayoutConfig: false,
    siteExplorerParams: {
      search: '',
      page: 1,
      page_size: 100,
    },
    isAllRender: [],
    activeTabKey: 'campaign',
    loadingSingleProjectData: false,
    isConfirmModalVisible: false,
    isEdited: false,
    layoutType: 'custom',
    layoutState: {lg: []},
    graphDataRb: 'clicks',
    localSeoLoading: false,
    creatingNotionBoard: false,
    addingNotionBoardTask: false,
    modalData: {
      openModal: false,
      title: '',
      status: 'In Progress',
      type: '',
      website: '',
      issueFound: '',
      fixed: '',
      dueDate: '',
      boardId: '',
      boardName: '',
      modalType: '',
      rankingFactor: '',
      timeSavedWithOtto: '',
    },
    loadingFb: true,
    loadingFbAge: true,
    loadingFbGender: true,
    loadingBoardsList: true,
    loadingglMatrix: false,
    loadinggl: false,
    loadingglTable: false,
    fbLoader: false,
    glLoader: false,
    showLayoutConfirmModal: {
      show: false,
      option: null,
    },
    resetChangesObject: {
      visible: null,
      option: null,
    },
  };
};
