
import React, {useState, useEffect, Dispatch, SetStateAction} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {Dropdown, Menu, Divider, Checkbox, Spin, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faPlus} from '@fortawesome/free-solid-svg-icons';
import {faCheck} from '@fortawesome/pro-regular-svg-icons';
import CommonButton from './CommonButton';
import {BusinessInfoModal, countries} from './BusinessInfoModal';
import {CitationBuilderTable} from './CitationBuilderTable';
import {LoadingOutlined} from '@ant-design/icons';
import {toJS} from 'mobx';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {faCoins} from '@fortawesome/pro-duotone-svg-icons';
import {TopupModal} from '../../otto-page-detail/Modals/topupModal';
import moment from 'moment';

export const keysMapping = {
  foursquare: 'Foursquare',
  dataaxle: 'Data Axle',
  neustar: 'Neustar',
  ypnetwork: 'YP Network',
  gpsnetwork: 'GPS Network',
};

type optionsType = 'quantity'
| 'price'
| 'additionally_included'
| 'current_quota'
| 'used_card'
| 'recurring_payment'
;

interface TopUpVisibilityProps {
  visibility: boolean;
  price: number;
  title: string;
  options: optionsType[];
  consumed: number | null;
  total: number | null;
  recurring: string;
  quotaKey: string;
  desc: string;
  nextPaymentAt?: string | null;
  buttonTxt: string;
  step?: number | null;
  localSeo?: boolean;
  overAllQuota?: number;
  multiplesOfStep?: boolean;
  unitIsMultipleOfStep?: boolean;
  recurringIsOptional?: boolean;
  recurringValue?: string;
  setRecurringValue?: Dispatch<SetStateAction<'no' | 'yes'>>;
}

const data = [
  {
    key: 'foursquare',
    companyName: keysMapping['foursquare'],
    img: '/img/foursquare.png',
    price: '25 Credits',
    supportedLocation: 'US, CA, AU',
    allCountries: true,
  },
  {
    key: 'dataaxle',
    companyName: keysMapping['dataaxle'],
    img: '/img/data-axle.png',
    price: '25 Credits',
    supportedLocation: 'US, CA',
  },
  {
    key: 'neustar',
    companyName: keysMapping['neustar'],
    img: '/img/neustar.png',
    price: '25 Credits',
    supportedLocation: 'US',
  },
  {
    key: 'ypnetwork',
    companyName: keysMapping['ypnetwork'],
    img: '/img/yp-network.png',
    price: '25 Credits',
    supportedLocation: 'US',
  },
  {
    key: 'gpsnetwork',
    companyName: keysMapping['gpsnetwork'],
    img: '/img/gps-network.png',
    price: '25 Credits',
    supportedLocation: 'US, CA, AU',
    allCountries: true,
  },
];

export const LocalCitations = observer(() => {
  const {
    ottoV2Store: {quotaAllocations, getOttoQuotaAllocations},
    localSeoScan: {loadSeoProjectsListV3, localSeoprojectsListV3},
    settings: {customer: {profile: {quotaUtilization, nextPaymentAt, isLinkgraph, trialExpiresAt}}},
    countries: {countriesData},
  } = useStore('');

  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState<TopUpVisibilityProps>({
    visibility: false,
    price: 0,
    title: '',
    options: [],
    consumed: 0,
    total: 0,
    recurring: '',
    quotaKey: '',
    desc: '',
    buttonTxt: '',
    step: 0,
    nextPaymentAt: null,
    unitIsMultipleOfStep: false,
    multiplesOfStep: false,
    overAllQuota: null,
  });

  const [selectedValue, setSelectedValue] = useState('1');
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [save, setSave] = useState(0);
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [check, setCheck] = useState(false);
  const [visible, setVisible] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [projectPage, setProjectPage] = useState(1);
  const [projectLoading, setProjectLoading] = useState(false);
  // const [searchText, setSearchText] = useState('');
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    const length = selectedRowKeys.length;
    length == 5 ? setCheck(true) : length < 5 ? setCheck(false) : '';
    const value =
      length > 0 ? length == 1 || length == 2 ? 5 : length == 3 ? 15 : length == 4 ? 20 : length == 5 ? 20 : 0 : 0;
    setSave(value);
    setTotal(length * 25);
    const discount =
      length > 1 ? length == 2 ? 5 : length == 3 ? 10 : length == 4 ? 15 : length == 5 ? 20 : 0 : 0;
    setDiscount(discount);
  }, [selectedRowKeys]);

  useEffect(() => {
    const filteredCountriesData = countries.map(i => {
      const matchedCountry = toJS(countriesData).find(country => i.code === country.code);

      return {
        ...i,
        flag: matchedCountry ? matchedCountry.emoji : undefined,
      };
    });
    setCountriesList(filteredCountriesData);
  }, [countriesData]);

  const antIcon = <LoadingOutlined style={{fontSize: 30, margin: '10 0 20', color: '#7f4ead'}} spin />;

  const tags = {
    tag1: <div style={{display: 'flex', justifyItems: 'center', padding: '2px, 5px, 2px, 5px'}}><FontAwesomeIcon style={{marginTop: '2px', marginRight: '5px'}} color='#2AC155' icon={faCheck}/><div>Added</div></div>,
    tag2: (
      <div
        style={{
          width: '132px',
          height: '22px',
          borderRadius: '45px',
          padding: '3px, 8px, 4px, 8px',
          textAlign: 'center',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#333539',
        }}
      ><FontAwesomeIcon style={{marginRight: '2px'}} color='white' icon={faPlus}/>{`Add and save ${save}%`}</div>
    ),
  };
  const handleRowClick = (record: any) => {
    const newSelectedRowKeys = [...selectedRowKeys];
    if (newSelectedRowKeys.includes(record.key)) {
      setSelectedRowKeys(newSelectedRowKeys.filter(key => key !== record.key));
    } else {
      setSelectedRowKeys([...newSelectedRowKeys, record.key]);
    }
  };

  const onLoadMore = async () => {
    setProjectLoading(true);
    const res = await loadSeoProjectsListV3(false, true, '', projectPage);
    if (toJS(localSeoprojectsListV3?.results)?.length && res?.success) {
      setProjectList(prev => [...prev, ...toJS(localSeoprojectsListV3?.results)]);
      setProjectPage(prev => prev + 1);
    }
    setProjectLoading(false);
  };

  const handleScroll = e => {
    const atBottom = Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
    if (atBottom && localSeoprojectsListV3?.next && !projectLoading) {
      onLoadMore();
    }
  };

  const handleBannerClick = () => {
    const newSelectedRowKeys = !check ? data.map(item => item.key) : [];
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleBannerCheckboxChange = (event:any) => {
    setCheck(event.target.checked);
    const newSelectedRowKeys:any = event.target.checked ? data.map(item => item.key) : [];
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleMenuClick = (business: any) => {
    setSelectedBusiness(business);
    setSelectedValue(business?.id);
    setBusinessName(business?.businessName);
    setAddress(business?.address);
  };

  useEffect(() => {
    loadSeoProjectsListV3();
    getOttoQuotaAllocations();
  }, []);

  useEffect(() => {
    (toJS(localSeoprojectsListV3?.results)?.length && !businessName) && handleMenuClick(toJS(localSeoprojectsListV3?.results)[0]);
  }, [toJS(localSeoprojectsListV3?.results)]);

  useEffect(() => {
    if (toJS(localSeoprojectsListV3?.results)?.length && !projectList?.length) {
      setProjectList(prev => [...prev, ...toJS(localSeoprojectsListV3?.results)]);
      setProjectPage(prev => prev + 1);
    }
  }, [toJS(localSeoprojectsListV3)]);

  const getSelectedBusiness = key => {
    try {
      return toJS(localSeoprojectsListV3?.results)?.[0][key];
    } catch (error) {
      return 'Select Business';
    }
  };

  const calculatedTotal = Math.ceil((total - (discount / 100) * total)) || 0;

  const menu = (
    <StyledMenu
      style={{position: 'absolute'}}
      selectedKeys={[selectedValue]}
      defaultValue={selectedValue}
      onScroll={handleScroll}
    >
      {/* <TableTopBar
        placeholder='Search Projects'
        onSearch={value => setSearchText(value)}
      /> */}
      {projectList?.map(project => (
        <StyledMenuItem selected={(businessName != '' ? businessName : getSelectedBusiness('businessName')) === project?.businessName} key={project?.id} onClick={() => {
          handleMenuClick(project);
        }}>
          <div style={{fontSize: 14, fontWeight: 500, color: '#121212'}}>{project?.businessName}</div>
          <div style={{fontSize: 12, fontWeight: 400, color: '#4E5156'}}>{project?.address}</div>
        </StyledMenuItem>
      ))}
      {projectLoading && (
        <div style={{textAlign: 'center'}}>
          <Spin indicator={antIcon} />
        </div>
      )}
    </StyledMenu>
  );

  const LocationMenu = ({codeList, allCountries}) => {
    return (
      <LocationContainer>
        {
          (allCountries ? countriesList : countriesList.filter(location => codeList.split(', ').includes(location?.code))).map(item => (<div key={item.label} style={{display: 'flex', alignItems: 'center', gap: 10}}>
            <img src={`${item.flag}`} style={{height: '12px', borderRadius: '2px'}}/>
            <RowText style={{color: 'black'}}>{item.label}</RowText></div>
          ))
        }
      </LocationContainer>
    );
  };

  const getTopupQuotaConf = key => {
    return quotaAllocations?.find(i => i?.quotaKey === key);
  };

  const getSummedHyperDriveQuotaValues = key => {
    return (quotaUtilization?.ca?.hyperdriveCredits[key] ?? 0) + (quotaUtilization?.ca?.hyperdriveCreditsPurchased[key] ?? 0);
  };

  const hyperDriveQuotaParams = {
    consumedData: getSummedHyperDriveQuotaValues('consumed'),
    totalData: getSummedHyperDriveQuotaValues('total'),
    recurringTotalCount: quotaUtilization?.ca?.hyperdriveCredits?.total,
    recurringConsumedCount: quotaUtilization?.ca?.hyperdriveCredits?.consumed,
    nonRecurringTotalCount: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.total,
    nonRecurringConsumedCount: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.consumed,
    heading: 'HyperDrive Credits',
    hideTopup: true,
    forHeatmap: false,
    showCta: true,
    isCenter: true,
    ctaLabel: 'Top up',
    ctaIcon: <FontAwesomeIcon icon={faCoins} color='#2AC155' style={{marginRight: '5px'}} />,
    varient: 'green',
    customCta: () => {
      const cloudStacksConf = getTopupQuotaConf('quota_ca_hyperdrive_credits');
      setTopUpBulkVisibility({
        visibility: true,
        price: 1,
        title: 'HyperDrive Quota',
        options: isLinkgraph ? ['price', 'current_quota', 'used_card'] : ['quantity', 'current_quota', 'used_card'],
        recurring: 'purchased',
        quotaKey: cloudStacksConf?.quotaKey,
        desc: 'per 1 credit',
        nextPaymentAt: nextPaymentAt,
        buttonTxt: 'credit',
        total: getSummedHyperDriveQuotaValues('total'),
        consumed: getSummedHyperDriveQuotaValues('consumed'),
        step: 1,
        multiplesOfStep: false,
        unitIsMultipleOfStep: false,
        recurringIsOptional: true,
      });
    },
  };

  const trialExpiresAtDateCheck = moment(trialExpiresAt)?.isValid() ? moment().isAfter(moment(trialExpiresAt)) : true;

  return (
    <>
      <Wrapper>
        <Hero>
          <HeroLeft>
            <QuotaInfoDetail {...hyperDriveQuotaParams} />
            <PageTitle>Data Aggregator & Network Submissions</PageTitle>
            <PageDescription>
              <li>Push your business data to hundreds of sites at once</li>
              <li>Get your data into mobile apps and GPS services</li>
              <li>Update your business data free for 12 months</li>
            </PageDescription>
            <DropdownLabel>Business</DropdownLabel>
            <StyledDropdown trigger={['click']} overlay={menu}>
              <div>
                <div>
                  <SelectedMenuItem>
                    <Tooltip title={businessName != '' ? businessName : getSelectedBusiness('businessName')}>
                      {businessName != '' ? businessName : getSelectedBusiness('businessName')}
                    </Tooltip>
                  </SelectedMenuItem>
                  <SubText>
                    {address != '' ? address : getSelectedBusiness('address')}
                  </SubText>
                </div>
                <DropdownIcon>
                  <FontAwesomeIcon icon={faCaretDown} />
                </DropdownIcon>
              </div>
            </StyledDropdown>
            <PaymentWrapper>
              <Header>
                <HeaderCell isFirst={true}>Data Aggregator</HeaderCell>
                <HeaderCell style={{width: '20%'}}>Location</HeaderCell>
                <HeaderCell>Base Price</HeaderCell>
              </Header>
              <BannerContainer onClick={handleBannerClick}>
                <StyledCheckbox checked={check} onChange={handleBannerCheckboxChange}>
                  <BannerText>Select all and{' '} <a style={{textDecoration: 'underline'}}>save 20%</a> on your submission</BannerText>
                </StyledCheckbox>
              </BannerContainer>
              {data.map(row => (
                <Container key={row.key}>
                  <Column isFirst={true} onClick={() => handleRowClick(row)}>
                    <StyledCheckbox checked={selectedRowKeys.includes(row.key)} style={{marginRight: 9}}/>
                    <ImageDiv><img src={`${row.img}`} /></ImageDiv>
                    <RowText>{row.companyName}</RowText>
                  </Column>
                  <Column style={{width: '20%'}}>
                    <LocationDropdown trigger={['click']} overlay={<LocationMenu codeList={row?.supportedLocation} allCountries={row?.allCountries} />} placement='bottomLeft'>
                      <div>
                        <RowText>
                          {row?.supportedLocation}{row?.allCountries ? '...' : ''}
                        </RowText>
                        <FontAwesomeIcon icon={faCaretDown} color='white' />
                      </div>
                    </LocationDropdown>
                  </Column>
                  <Column>
                    <RowText>{row.price}</RowText>
                    {selectedRowKeys?.length ? <Tag>{selectedRowKeys.includes(row.key) ? tags.tag1 : tags.tag2}</Tag> : <></>}
                  </Column>
                </Container>
              ))}
            </PaymentWrapper>
            <div style={{width: 386}}><Divider style={{backgroundColor: '#4E5156', height: '1px', margin: '21px 0px 10px 0px'}}/></div>
            <div style={{color: 'white'}}>
              <StyledDiscount>
              Discount: <span>{discount}%</span>
              </StyledDiscount>
              <StyledDiscount>
              Total: <span>{calculatedTotal} Credits</span>
              </StyledDiscount>
            </div>
            <Tooltip title={trialExpiresAtDateCheck ? '' : 'Access to this feature is restricted for free trial users.'}>
              <div style={{width: 'fit-content'}}>
                <CommonButton disabled={!businessName || !selectedRowKeys.length || !trialExpiresAtDateCheck} action={() => {
                  setVisible(true);
                  setIsEdit(false);
                }} />
              </div>
            </Tooltip>
          </HeroLeft>
          {/* <HeroRight>
            <div className='display-end'>
              <iframe width='400'
                height='264'
                src='https://www.youtube.com/embed/watch?v=K3q1cOyotC0&list=PLEpY4e8tcdVW1ib_iaBqvnDYV5DPL6Y4T&index=3&autoplay=1&mute=1'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                style={{borderRadius: '12px'}}></iframe>
            </div>
            <div>
              <span className='still-have-questions'>Still have questions? </span>
              <a className='complete-guide-link' onClick={() => window.open('https://linkgraph.com/how-to-do-seo-copywriting-with-the-seo-content-assistant/', '_blank')}>Read the Full Guide.</a>
            </div>
          </HeroRight> */}
        </Hero>
      </Wrapper>
      <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
      <CitationBuilderTable setVisible={setVisible} selectedId={selectedId} setSelectedId={setSelectedId} setIsEdit={setIsEdit}/>
      {visible && selectedBusiness && <BusinessInfoModal
        visible={visible}
        setVisible={setVisible}
        aggregators={selectedRowKeys}
        total={calculatedTotal}
        businessName={businessName}
        selectedBusiness={selectedBusiness}
        selectedId={selectedId}
        isEdit={isEdit}
      />}
    </>
  );
});

const Wrapper = styled.div`
  height: auto;
  background-color: #1F2125;
`;

const Hero = styled.div`
  height: 830px;
  background-color: #1F2125;
  width: 100%;
  padding: 58px 85px 10px 85px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1440px) {
    padding: 40px;
  }
  @media screen and (max-width: 800px) {
    width: fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 20px 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
  }
`;

const HeroLeft = styled.div`
  width: 50%;
  @media screen and (max-width: 820px) {
    width: fit-content;
    max-width: 100%;
  }
`;
// const HeroRight = styled.div`
//   padding-top: 150px;
//   width: 50%;
//   text-align: center;
//   @media screen and (max-width: 820px) {
//     padding-top: 20px;
//     margin-bottom: 100px;
//     max-width: 100%;
//     width: 100%;
//   }

//   .display-end {
//     justify-content: center;
//     display: flex;
//     margin-bottom: 22px;
//     @media screen and (max-width: 820px) {
//       justify-content: flex-start;
//     }
//   }
//   .still-have-questions {
//     font-size: 14px;
//     font-weight: 400;
//     color: #FFFFFF;
//   }

//   .complete-guide-link {
//     font-size: 14px;
//     font-weight: 400;
//     color: #34AEF3;
//     &:hover {
//       text-decoration: underline;
//     }
//   }

// `;
const PageTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #FFFFFF;
  @media screen and (max-width: 830px) {
    font-size: 24px !important;
  }
  @media screen and (max-width: 600px) {
    font-size: 22px !important;
  }
`;

const PageDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #E8E8E8;
  margin-top: 18px;
  padding-left: 5px;
  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
`;

const DropdownLabel = styled.div`
  color: #E8E8E8;
  margin-top: 19px;
  font-size: 12px;
`;

const DropdownIcon = styled.div`
  position: absolute;
  color: #121212;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledDropdown = styled(Dropdown)`
  background-color: #fff;
  width: 380px;
  height: 56px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: #E8E8E8;
  cursor: pointer;
  position: relative;
  margin-top: 7px;
  margin-bottom: 24px;
  @media screen and (max-width: 820px) {
    width: auto;
    max-width: 380px;
  }
  &:hover {
    background-color: rgb(237, 237, 241);
  }
  &.selected {
    background-color: rgb(237, 237, 241);
  }
`;


const LocationDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledMenu = styled(Menu)`
  background-color: #fff;
  color: #E8E8E8;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(237, 237, 241);
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: rgba(78, 81, 86, 0.5);
    border-radius: 99px;
  }
  &::-webkit-scrollbar-track {
    width: 8px;
  }
`;

const LocationContainer = styled.div`
  background-color: #fff;
  color: #E8E8E8;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  max-height: 100px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.8);
      border-radius: 12px;
    }
            
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
`;

const StyledMenuItem = styled(Menu.Item)<{selected?: boolean}>`
  background-color: #fff;
  font-weight: 500;
  ${p => p?.selected ? 'background-color: rgb(237, 237, 241);' : ''}
  &:hover {
    background-color: rgb(237, 237, 241);
  }
  &.selected {
    background-color: rgb(237, 237, 241);
  }
`;

const SelectedMenuItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #121212;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 330px;
`;

const SubText = styled.div`
  font-size: 12px; 
  font-weight: 400px;
  color: #4E5156;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 330px;
  @media screen and (max-width: 600px) {
    max-width: 75%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #2AC155;
  border-color: #2AC155;
}
.ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}
`;

const PaymentWrapper = styled.div`
  @media screen and (max-width: 600px) {
    overflow-x: scroll;
    max-width: 100%;
  }
`;

const BannerContainer = styled.div`
  background-color: #34aef340;
  color: white;
  width: 380px;
  height: 28px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    background-color: #2e94c740;
  }
  @media screen and (max-width: 600px) {
    width: auto;
  }
`;

const StyledDiscount = styled.div`
  font-size: 14px;
  color: #E8E8E8;
  line-height: 16px;
  font-weight: 400;
  margin-left: 8px;
  margin-top: 10px;
  
  span {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const Header = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  width: 550px;
  height: 28px;
  color: white;
  padding-left: 10px;
  @media screen and (max-width: 570px) {
    width: 100%;
  }
`;

const HeaderCell = styled.div<{isFirst?: boolean}>`
  width: 30%;
  color: #E8E8E8;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  @media screen and (max-width: 570px) {
    ${p => p?.isFirst ? 'width: 60% !important; min-width: 60% !important' : 'width: auto !important;'}
  }
`;

const BannerText = styled.div`
  font-size: 12px;
  font-weight: 500px;
  color: white;
`;

const Container = styled.div`
  width: 550px;
  display: flex;
  height: 28px;
  margin-bottom: 7px;
  padding-left: 8px;
  cursor: pointer;
  @media screen and (max-width: 570px) {
    width: 100%;
  }
`;

const Column = styled.div<{isFirst?: boolean}>`
  width: 30%; 
  display: flex;
  align-items: center;
  @media screen and (max-width: 570px) {
    ${p => p?.isFirst ? 'width: 60% !important; min-width: 60% !important' : 'width: auto !important;'}
  }
`;

const ImageDiv = styled.div`
  height: 28px; 
  margin-right: 9px;
`;

const RowText = styled.div`
  color: #FFFFFF;
  font-size: 14px; 
  font-weight: 500px;
  white-space: nowrap;
`;

const Tag = styled.div`
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 500px; 
  color: #FFFFFF;
  margin-left: 10px; 
  align-items: center;
`;
